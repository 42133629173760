
import { decamelize, pascalize } from "humps"
import { updateQuery } from "@evercam/shared/utils"
import { AnalyticsEvent } from "@evercam/shared/types"
import SessionsSearchFilters from "@/components/users/SessionsSearchFilters"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { mapStores } from "pinia"
import { useAccountStore } from "@/stores/account"

export default {
  name: "Sessions",
  components: {
    SessionsSearchFilters,
  },
  data() {
    return {
      options: {},
      sessions: [],
      selectedItems: [],
      total: 0,
      loading: false,
      sortBy: "created_at",
      sortDesc: true,
      optionsWatcherEnabled: false,
    }
  },
  computed: {
    ...mapStores(useAccountStore),
    computedHeaders() {
      return this.headers.filter((header) => header.visible)
    },
    headers() {
      return [
        {
          value: "id",
          text: this.$t("content.sessions.headers.id"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
        },
        {
          value: "ipAddress",
          text: this.$t("content.sessions.headers.ip_address"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
          sortable: false,
        },
        {
          value: "createdAt",
          text: this.$t("content.sessions.headers.createdAt"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
        },
        {
          value: "browser",
          text: this.$t("content.sessions.headers.browser"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
        },
        {
          value: "os",
          text: this.$t("content.sessions.headers.os"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
        },
        {
          value: "lastUsedAt",
          text: this.$t("content.sessions.headers.lastUsedAt"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
        },
        {
          value: "loginType",
          text: this.$t("content.sessions.headers.loginType"),
          class: "subtitle-1 table-header",
          visible:
            this.accountStore.isAdmin || this.accountStore.isImpersonationLogin,
          width: 100,
          sortable: false,
        },
        {
          value: "actions",
          text: this.$t("content.sessions.headers.actions"),
          class: "subtitle-1 table-header",
          visible: true,
          width: 100,
          sortable: false,
        },
      ]
    },
  },
  watch: {
    options: {
      handler() {
        if (this.optionsWatcherEnabled) {
          this.getActiveSessions()
        }
        this.optionsWatcherEnabled = true
      },
      deep: true,
    },
  },
  methods: {
    humanizeDate(date) {
      return this.$moment(date).format("llll")
    },
    onItemsPerPageChange(itemsPerPage) {
      this.$analytics.saveEvent(
        AnalyticsEvent.SettingsAccountClickRowsPerPage,
        {
          limit: itemsPerPage,
        }
      )
    },
    relativeDate(date) {
      return this.$moment(date).fromNow()
    },
    revokeSession(id) {
      this.$analytics.saveEvent(AnalyticsEvent.SettingsAccountRevokeSession, {
        sessionToRevoke: id,
      })
      EvercamApi.users
        .deleteActiveSession(id, {
          authorization: this.accountStore.token,
        })
        .then(() => {
          this.getActiveSessions()
        })
        .catch((error) => {
          this.$notifications.error({ error })
        })
    },
    async revokeAll() {
      this.$analytics.saveEvent(AnalyticsEvent.SettingsAccountRevokeSession, {
        sessionsToRevoke: this.selectedItems.map((e) => e.id).join(","),
      })
      if (this.selectedItems.length === 0) {
        this.$notifications.warn(this.$t("content.sessions.minimum_selection"))
      } else {
        if (
          await this.$confirmDialog.open({
            title: this.$t("content.sessions.revoke_session"),
            message: this.$t("content.sessions.revoke_confirm"),
            options: { width: 360 },
          })
        ) {
          this.selectedItems.forEach((session) => {
            this.revokeSession(session.id)
          })
          this.selectedItems = []

          this.$notifications.success(
            this.$t("content.sessions.sessions_deleted")
          )
          this.getActiveSessions()
        } else {
          this.$analytics.saveEvent(
            AnalyticsEvent.SettingsAccountCancelRevokeSession
          )
        }
      }
    },
    getPascalize(item) {
      return pascalize(item.type)
    },
    async getActiveSessions(params = {}) {
      try {
        let { sortBy, sortDesc, page, itemsPerPage } = this.options
        sortBy = decamelize(sortBy[0])
        updateQuery(params)
        this.loading = true
        let response = await EvercamApi.users.getActiveSessions({
          authorization: this.accountStore.token,
          ...params,
          sort: `${sortBy}|${this.whichSort(sortDesc[0])}`,
          limit: itemsPerPage,
          page: page,
        })

        this.sessions = response.items
        this.total = response.total
      } catch (error) {
        this.$notifications.error({
          text: `${this.$t("content.sessions.load_sessions_error")}`,
          error,
        })
      } finally {
        this.loading = false
      }
    },
    whichSort(type) {
      if (type) {
        return "desc"
      } else {
        return "asc"
      }
    },
  },
}
